@import "../../foundation/setting";

.p-common-headline {
	margin-bottom: 3.5rem;
	color: inherit;
	font-weight: bold;
	font-size: 3.6rem;
	position: relative;
	@include line-height(36, 36);
	@include mq('max', 'xxl') {
		font-size: 4.2rem;
		@include line-height(42, 42);
	}
	@include mq('max') {
		margin-bottom: 1.4rem;
		@include line-height(34, 20);
	}
	@include mq('max', 'md') {
		font-size: 2.4rem;
	}
	.word {
		position: relative;
		display: inline-block;
		overflow: hidden;
		vertical-align: bottom;
		-webkit-transform: translate(0, 20%);
		transform: translate(0, 20%);
		transition: 1s cubic-bezier(0.36, 0.14, 0, 1);
		.o {
			display: inline-block;
			vertical-align: bottom;
			-webkit-transform: translate(0, 100%);
			transform: translate(0, 100%);
			transition: 1s cubic-bezier(0.36, 0.14, 0, 1);
		}
	}
	&.is-active {
		.word {
			transform: translate(0, 0);
			.o {
				transform: translate(0, 0);
			}
		}
	}
}


/*current-nav
---------------------------------------------------------- */
.p-side-nav {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	@include mq('max') {
		display: none;
	}
}

.p-side-nav__list {
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.p-side-nav__item {
	&:not(:first-child) {
		margin-top: 2rem;
	}
	&[data-link-current="true"] {
		.p-side-nav__list-link {
			&:after {
				transform: scaleX(1);
			}
		}
	}
}

.p-side-nav__list-link {
	position: relative;
	display: block;
	display: block;
	width: 1.6rem;
	height: 1.6rem;
	margin: auto;
	border: solid 2px $color-maintone01;
	border-radius: 50%;
	&:after {
		position: absolute;
		top: 0;
		right: -1rem;
		bottom: 0;
		width: 1.6rem;
		height: 2px;
		margin: auto;
		background-color: $color-maintone01;
		content: '';
		transform: scaleX(0);
		transform-origin: 0 0;
		transition: transform 0.4s cubic-bezier(.39, .575, .565, 1);
	}
}


/*hero
---------------------------------------------------------- */
.p-mv {
	position: relative;
	margin-bottom: 10rem;
	@include mq('max') {
		margin-bottom: 2rem;
    }
}

.p-mv__inner {
	height: 70rem;
	position: relative;
	@include mq('max') {
		height: 94vw;
		margin-bottom: 2rem;
    }
}

.p-mv__img {
	width: 100%;
	height: 100%;
	border-radius: 2rem;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.p-mv__inner {
	.p-mv__button {
		transition: all .2s;
		&:hover {
			opacity: 0.8;
		}
	}
	/* スライダー01 */
	&.\-slide01 {
		.p-mv__img {
			background-image: url(../../../img/top/mv_img_pc.jpg);
			@include mq('max') {
				background-image: url(../../../img/top/mv_img_sp.jpg);
			}
		}
		.p-mv__text {
			position: absolute;
			width: 50%;
			height: 100%;
			left: 0;
			top: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			@include mq('max') {
				justify-content: flex-start;
				width: 100%;
				align-items: baseline;
				padding: 4rem 2rem;
			}
			.p-mv__text-main,
			.p-mv__text-sub {
				color: $color-white;
				text-shadow: 0 0 1rem rgba(0, 0, 0, 0.7);
				text-align: center;
				font-weight: bold;
				@include mq('max') {
					width: 57%;
					text-align: left;
				}
			}
			.p-mv__text-main {
				font-size: 5rem;
				line-height: 1.5;
				margin-bottom: 2rem;
				@include mq('max') {
					font-size: 4vw;
				}
			}
			.p-mv__text-main-lg {
				font-size: 7.2rem;
				@include mq('max') {
					font-size: 7vw;
				}
			}
			.p-mv__text-sub {
				font-size: 3rem;
				line-height: 1.8;
				margin-bottom: 3rem;
				@include mq('max') {
					font-size: 3vw;
					line-height: 2;
				}
			}
			.p-mv__button {
				color: $color-white;
				padding: 2rem 3rem 2rem 4rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
				background-color: $color-red;
				border: 1px solid $color-white;
				border-radius: 50px;
				font-size: 1.8rem;
				font-weight: bold;
				.p-mv__button-text {
					padding-right: 5rem;
				}
				@include mq('max') {
					position: absolute;
					bottom: 6rem;
					width: calc(100% - 4rem);
					font-size: 3.2vw;
					padding: 1.5rem 2rem;
					.p-mv__button-text {
						padding-right: 0;
						width: 100%;
						text-align: center;
						line-height: 1.5;
					}
				}
				@include mq('max', 'md') {
					padding: 1rem 2rem;
				}
				@media screen and (max-width: 350px) {
					padding: 0.5rem 2rem;
				}
			}
		}
	}
}

.p-mv__news {
	background-color: $color-white;
	border: 3px solid $color-maintone01;
	border-radius: 50px;
	position: absolute;
	bottom: -4rem;
	right: 4rem;
	padding: 0 4rem;
	width: 65rem;
	height: 8rem;
	display: flex;
	justify-content: left;
	align-items: center;
	z-index: 1;
	time {
		font-size: 1.4rem;
		color: $color-maintone01;
		padding-right: 2.3rem;
	}
	@include mq('max') {
		position: static;
		width: 87%;
		margin: 0 auto;
		border-radius: 1rem;
		padding: 2rem;
		border: 1px solid #1999B8;
		display: block;
		height: auto;
		margin-bottom: 4rem;
		time {
			display: block;
			margin-bottom: 0.5rem;
		}
	}
}

/*p-search
---------------------------------------------------------- */
.p-search {
	margin-bottom: 10rem;
	@include mq('max') {
		margin-bottom: 5rem;
    }
}
.p-search__content {
	position: relative;
}
.p-search__content-inner {
	width: 100%;
	max-width: calc(1180px + 32rem);
	margin: auto;
	padding-right: 16rem;
	padding-left: 16rem;
	@include mq('max') {
        padding-right: 1.5rem;
		padding-left: 1.5rem;
    }
}

.p-search__body {
	background-color: #fff7cc;
	border: 3px solid $color-maintone01;
	border-radius: 2rem;
	padding: 5rem;
	@include mq('max') {
        padding: 3rem;
    }
	@media screen and (max-width: 350px) {
		padding: 3rem 2rem;
	}
}

.p-search__bg {
	width: 100%;
	height: 38rem;
	background-color: $color-maintone01;
	position: absolute;
	bottom: -10rem;
	z-index: -1;
}

/*p-job
---------------------------------------------------------- */
.p-job__content {
	background-color: $color-maintone03;
	padding: 10rem 0;
	@include mq('max') {
		padding: 5rem 0;
    }
}

.p-job__content-inner {
	width: 100%;
	max-width: calc(1180px + 32rem);
	margin: auto;
	padding-right: 16rem;
	padding-left: 16rem;
	display: grid;
	grid-template-columns: 1fr 93rem;
	@include mq('max') {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
        grid-template-columns: 1fr;
    }
}

.p-job__list-item {
	background-color: $color-white;
	border: 1px solid $color-maintone01;
	border-radius: 0.6rem;
	position: relative;
	transition: all .2s;
	&:not(:last-child) {
		margin-bottom: 2rem;
	}
	time {
		color: #777777;
		padding-right: 3rem;
	}
	i {
		position: absolute;
		top: 50%;
		right: 3rem;
		transform: translateY(-50%);
		color: $color-maintone01;
	}
	a {
		display: block;
		width: 100%;
		height: 100%;
		padding: 2.5rem 5rem 2.5rem 3rem;
	}
	@include mq('max') {
		a {
			padding: 1rem 4rem 1rem 1rem;
		}
		i {
			right: 1rem ;
		}
    }
	&:hover {
		background-color: $color-maintone03;
	}
	&.\-blue {
		background-color: #EFF8F9;
		&:hover {
			background-color: $color-white;
		}
	}
}

.p-job__list-head {
	display: flex;
	justify-content: left;
	align-items: center;
	gap: 10px;
	@include mq('max') {
		flex-direction: column;
		align-items: start;
		gap: 5px;
    }
}

.p-job__list-label-list {
	display: flex;
	gap: 5px;
	flex-wrap: wrap;
}

.p-job__list-label {
	font-size: 1.4rem;
	color: $color-white;
	padding: 0.3rem 1.5rem;
	border-radius: 50px;
	&.\-blue {
		background-color: $color-blue;
	}
	&.\-green {
		background-color: $color-green;
	}
	&.\-yellow {
		background-color: $color-yellow;
	}
	&.\-orange {
		background-color: $color-orange;
	}
	@include mq('max') {
		font-size: 1.2rem;
    }
}

.p-job__list-title {
	font-weight: bold;
}

.p-job__list-detail {
	font-size: 1.4rem;
	@include mq('max') {
		font-size: 1.2rem;
    }
}

.p-time__list-item {
	display: flex;
	margin-top: 1.5rem;
	&:first-child {
		margin-top: 0;
	}
	time {
		width: 13rem;
		color: $color-maintone01;
	}
	a {
		&:hover {
			text-decoration: underline;
		}
	}
	@include mq('max') {
		flex-direction: column;
		margin-top: 1rem;
    }
}

.p-time__text-strong {
	font-size: 1.8rem;
	font-weight: bold;
	@include mq('max') {
		font-size: 1.6rem;
    }
}

/*p-work
---------------------------------------------------------- */
.p-work {
	background-image: url(../../../img/top/bg_work.jpg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	padding: 10rem 0;
	margin-bottom: 10rem;
	@include mq('max') {
		padding: 5rem 0;
		margin-bottom: 5rem;
    }
}

.p-work__list {
	display: grid;
	grid-template-columns: 40rem 40rem 40rem;
	grid-column-gap: 5rem;
	place-content: center;
	@include mq('max') {
		grid-template-columns: 1fr;
		padding: 0 2rem;
    }
	@include mq('max', 'md') {
		padding: 0 1rem;
    }
}

.p-work__list-item {
	height: 40rem;
	padding: 1rem;
	border-radius: 50%;
	transition: all .2s;
	&:hover {
		opacity: 0.7;
	}
	&.\-attention {
		border: 4px solid $color-maintone01;
	}
	&.\-production {
		border: 4px solid $color-green;
	}
	&.\-job {
		border: 4px solid $color-yellow;
	}
	@include mq('max') {
		height: auto;
		width: 50%;
		&.\-attention {
		}
		&.\-production {
			margin-top: -13%;
			justify-self: right;
		}
		&.\-job {
			margin-top: -13%;
		}
    }
	@include mq('max', 'md') {
		width: 58%;
		padding: 0.5rem;
    }
}

.p-work__list-item-inner {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-repeat: no-repeat;
	span {
		display: block;
		color: $color-white;
		text-align: center;
	}
	a {
		position: relative;
	}
	@include mq('max') {
		background-position: unset;
    }
}

.p-work__list-text {
	width: 100%;
	position: absolute;
	top: 50%;
	right: 50%;
	transform: translate(50%, -50%);
}

.p-work__list-title {
	font-size: 3.2rem;
	@include mq('max') {
		font-size: 4vw;
    }
}

.p-work__list-title-en {
	font-size: 2rem;
	font-family: $font-en;
	@include mq('max') {
		font-size: 2.5vw;
    }
}

/*p-banner
---------------------------------------------------------- */
.p-banner {
	width: 100%;
	max-width: calc(1180px + 32rem);
	margin: auto;
	padding-right: 16rem;
	padding-left: 16rem;
	@include mq('max') {
		padding-right: 2rem;
		padding-left: 2rem;
    }
}

.p-banner__list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 4rem;
	grid-row-gap: 2rem;
	li {
		transition: all .2s;
		&:hover {
			opacity: 0.7;
		}
	}
	@include mq('max') {
		grid-column-gap: 2rem;
    }
	@include mq('max', 'md') {
		grid-template-columns: 1fr;
		li {
			width: 50%;
			margin: 0 auto;
			img {
				width: 100%;
			}
		}
    }
	@media screen and (max-width: 400px) {
		li {
			width: 100%;
		}
	}
}